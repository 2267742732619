<template>
  <div class="filter-dropdown">
    <Dropdown v-model="sort" :options="sortOptions" :optionValue="getVal" optionLabel="label" placeholder="Эрэмбэ" @change="onChange" :showClear="true"/>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
  name: "SortDropdown",
  components: {
    Dropdown
  },
  data() {
    return {
      sort: null
    }
  },

  created() {
    if(this.$route.params.sort != 'all'){
      this.sort = this.$route.params.sort;
    }
  },

  computed: {
    sortOptions() {
      return [
        {
          value: "new",
          label: "Шинэ",
        },
        {
          value: "old",
          label: "Хуучин",
        },
        {
          value: "a-z",
          label: "A-Я",
        },
        {
          value: "z-a",
          label: "Я-A",
        },
      ];
    }
  },

  methods: {
    onChange(val){
      let sort = val.value;
      if(sort == null){
        sort = 'all'
      }

      let cat = this.$route.params.cat ? this.$route.params.cat : 'all';
      let season = this.$route.params.season ? this.$route.params.season : 'all';
      let status = this.$route.params.status ? this.$route.params.status : 'all';

      this.$router.push(`/series/${cat}/${season}/${status}/${sort}`)
    },

    getVal(item){
      return item.value.toString();
    }
  }
}
</script>

<style scoped>

</style>