// import 'es6-promise/auto'
// import 'es6-promise/auto'
import {createStore} from 'vuex'
import * as actions from "./actions";
import * as mutations from "./mutations";
import getters from "./getters";


const store = createStore({
    // plugins: [createLogger()],
    strict: true,
    state: {
        //User data
        mirror: window.init.mirror,
        app: window.init.app,
        user: window.init.user,
        isActive: window.init.isActive,
        isCardActive: window.init.isCardActive,
        isMobileBlock: window.init.isMobileBlock,
        isMobileDevice: window.init.isMobileDevice,
        isLoadingAuth: true,
        isLoadingLatestEps: true,
        showMenu: false,

        //General
        backLink: "/",
        isModalActive: false,

        homeSlide: null,
        programSlide: null,
        mediaSlide: null,
        movieSlide: null,
        videoSlide: null,
        pageSlide: null,

        //Medias
        latestMovies: [],
        popularMedias: [],
        upComingMedias: [],
        homeCatMedias: [],
        mediaList: [],
        likedMediaList: [],
        media: null,
        actors: [],
        relatedMedias: [],
        seasonMedias: [],
        cats: [],
        seasons: [],
        suggestedMedias: [],

        //Episode
        latestEpisodes: [],
        historyEpisodes: [],

        //Episode watch
        isLoadingEpisode: false,
        episode: null,
        episodes: [],
        allEpisodes: [],
        episodeGroupList: [],
        canWatch: false,
        isFree: false,
        warnMsg: "",
        comments: [],
        commentCount: 0,

        //Content
        contentData: null,

        //Payment
        paymentData: null,
        paymentOptions: [],

        //Movies
        movieCats: [],
        movies: [],
        movie: null,

        //audio
        audioPath: null,
        checkAudioPath: null,
        audioImage: null,
        audioTitle: null,
        audioCreator: null,
        isPlaying: false,
        audio: null,
        playlist: [],
        currentTrackIndex: 0,
        volume: 1,
        conf: null,
        baseUrl: 'https://www.bloomberg.nnc.mn',
        showPlayer: false,
        playerCurrentTime: 0
    },

    getters: getters,
    mutations,
    actions,
})

export default store;