<template>
  <div class="program-carousel">
    <div v-if="programs == null" class="live-program">
      <div class="head-section">
        <h3 class="active">Хөтөлбөр</h3>
      </div>
      <ul class="program-list">
        <li class="flex items-center flex-col justify-center">
          <img src="/img/error-seeroo.a1369856.png" size="185" class="static thumb-img" alt="no-program”" lazy="error">
          <p class="text-gray-400">Одоогоор хөтөлбөр байхгүй байна</p>
        </li>
      </ul>
    </div>
    <div v-else class="live-program" v-for="(program, index) in programs" :key="program.id"
      :style="convertDate(program.date) ? 'display: block' : 'display: none'" v-show="index === currentIndex">
      <div class="head-section">
        <div class="cursor-pointer flex items-center justify-center arrow-btn" @click="prev"
          :class="currentIndex > 0 ? '' : 'disabled'">
          <IconArrowLeft class="w-5 h-8" />
        </div>
        <h3 v-if="today !== program.date">{{ program.title }}</h3>
        <h3 v-else class="active">Өнөөдрийн хөтөлбөр</h3>
        <div class="cursor-pointer flex items-center justify-center arrow-btn" @click="next"
          :class="currentIndex < programs.length - 1 ? '' : 'disabled'">
          <IconArrowRight class="w-5 h-8" />
        </div>
      </div>

      <ul class="program-list">
        <li v-for="(p, i) in program.programs" :key="p.id"
          :class="convertTime(p.start_time) <= now && convertTime(p.end_time) >= now && convertDate(program.date) ? 'active' : ''">
          <div class="info">
            <strong>{{ i + 1 }}</strong>
            <small>{{ convertFormat(p.start_time) }}-{{ convertFormat(p.end_time) }}</small>
          </div>
          <p class="text-white">{{ p.title }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import useDevice from "@hooks/useDevice";
import IconArrowRight from "~icons/ic/outline-arrow-forward-ios";
import IconArrowLeft from "~icons/ic/outline-arrow-back-ios";

export default {
  components: {
    IconArrowRight,
    IconArrowLeft,
  },
  props: ["action", "getter"],
  setup() {
    const { isDesktop } = useDevice();
    return { isDesktop };
  },
  computed: {
    programs() {
      return this.$store.state[this.getter];
    },
  },
  data() {
    return {
      isLoading: false,
      today: null,
      now: null,
      currentIndex: 0,
    };
  },

  mounted() {
  },

  created() {
    this.timeInSeconds = new Date();
    this.today = new Date().toISOString().slice(0, 10);
    this.now = this.timeInSeconds.getHours() * 3600 + this.timeInSeconds.getMinutes() * 60 + this.timeInSeconds.getSeconds();
    this.$store.dispatch(this.action, { vm: this, multi: true });
    this.$watch(
      "programs",
      (newPrograms) => {
        if (newPrograms && Array.isArray(newPrograms)) {
          const todayIndex = newPrograms.findIndex(
            (program) => program.date === this.today
          );
          if (todayIndex !== -1) {
            this.currentIndex = todayIndex;
          }
        }
      },
      { immediate: true } // programs өөрчлөгдөх үед шууд гүйцэтгэнэ
    );
    this.updateTime(); // Одоогийн цагийг тохируулах
    this.scrollToCurrentProgram(); // Эхний скролл хийх

    this.timeInterval = setInterval(() => {
      this.updateTime(); // Цаг шинэчлэх бүрт скролл хийх
    }, 3000);
  },

  methods: {
    convertTime(time) {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    },
    convertFormat(time) {
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
    },
    convertDate(date) {
      let date1 = new Date(this.today);
      let date2 = new Date(date);
      return date1.getTime() === date2.getTime();
    },
    prev() {
      if (this.currentIndex > 0) {
        console.log('here prev')
        this.currentIndex--;
      }
    },
    next() {
      if (this.currentIndex < this.programs.length - 1) {
        console.log('here next')
        this.currentIndex++;
      }
    },

    scrollToCurrentProgram() {
      const programList = document.querySelectorAll('.program-list li'); // Бүх програмыг сонгоно
      programList.forEach((el) => {
        if (el.classList.contains('active')) { // `active` класс байгаа эсэхийг шалгана
          el.scrollIntoView({ behavior: 'smooth', block: 'center' }); // Скролл хийх
        }
      });
    },

    updateTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      this.currentTime = `${hours}:${minutes}:${seconds}`;

      // Скролл функцыг цаг шинэчлэгдсэний дараа дуудах
      // this.scrollToCurrentProgram();
    },
  },
};
</script>

<style scoped>
.live-program[v-show="true"] {
  display: block;
  opacity: 1;
}
</style>
