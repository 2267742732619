<template>
  <div class="news-latter">
    <div class="news-latter-detail">
      <div class="container">
        <h1 class="title" v-if="news.title">{{news.title}}</h1>
        <img :src="imageUrl(news.image)" alt="" v-if="news.image"
             class="image"/>
        <div class="news-latter-tag" v-if="news.tags.length>0">
          <div class="tags"  v-for=" t in news.tags" :key="t.id">
            {{ t.title }}
          </div>
        </div>
        <div class="short-description" v-if="news.description"> {{news.description}}</div>
        <div class="description"  v-html="news.body" v-if="news.body"></div>
      </div>

    </div>


  </div>
</template>
<script>
import moment from "moment/moment";
import {imageUrl} from "@utils";
import axios from "axios";
import config from "@store/_boot/config";
// import router from "@/router";

export default {
  name: 'NewsLatterDetail',

  data() {
    return {
      id:null,
      isLoading: true,
      isLoadingMore: false,
      hasMore: false,
      page: 1,
      news: [],
      newsSpecial: [],
      newsPerPage: '',
      totalNews: 0,
      currentPage: 1,
      lastPage: 0,
    };
  },
  created() {
    this.id = this.$route.params?.id
    this.getNewDetail();
    // console.log(this.$route.params?.id)
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalNews / this.newsPerPage);
    },
    lastPageColor() {
      return this.lastPage === this.currentPage ? 'grey' : '';
    },

  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    imageUrl,
    getNewDetail() {
      axios.get(`${config.HOST}/api/web/all-news-latter/${this.id}?page=${this.currentPage}`).then(({data}) => {

          console.log('data', data)
          this.news = data;

      })
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        console.log("dsvssdvsdv------>")
        this.currentPage++;
        this.getNews();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getNews();
      }
    }
  },

};

</script>
