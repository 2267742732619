<template>
  <div class="page-news">
    <div class="page-header large-header newsletter">
      <div class="header-layer">
        <div class="container">
          <div class="header-title">
            <small>Daily newsletter</small>
            <h3>Товхимол</h3>
          </div>
        </div>
      </div>
    </div>

    <div class="news-latter-section">
      <div class="container">
        <h1 class="font-bold text-[19px] my-4 text-left color-white">Онцлох нийтлэл</h1>
        <div class="blog2">
          <div class="grid gap-x-12 gap-y-8 md:grid-cols-4 grid-cols-3 md:grid-rows-2">
            <div v-for="(item, index) in newsSpecial" :key="item.id"
                 :class="index === 0 ? 'item large md:col-span-2 md:row-span-2 col-span-1':'item col-span-1' ">
              <div class="img-cover" :style="`background-image: url('${imageUrl(item.image)}')`"></div>
              <div class="is-free" v-if="item.is_free === 1">Үнэгүй</div>

              <!--              <img :src="imageUrl(item.image)" class="img-fluid" alt="">-->
              <div class="bottom-fade"></div>
              <div class="title">
                <!--                <h6>Rental</h6>-->
                <h4>{{ item.title }}</h4>
              </div>
              <div class="curv-butn icon-bg">
                <a href="javascript:void(0)" @click="detail(item)" class="vid">
                  <div class="icon">
                    <i class="icon-show"><span>{{
                        item.created_at.toString().substring(8, 10)
                      }}<br><i>{{ months[parseInt(item.created_at.toString().substring(5, 7)) - 1] }}</i></span>
                    </i><i class="isax isax-arrow-right-1 icon-hidden"></i>
                  </div>
                </a>

                <div class="br-left-top">
                  <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-11 h-11">
                    <path d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                          fill="#000000"></path>
                  </svg>
                </div>
                <div class="br-right-bottom">
                  <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-11 h-11">
                    <path d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                          fill="#000000"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6">
          <div v-if="newsTags.length > 0">
            <div class="news-latter-tags">
              <div :class="selectedTag === tag.id ? 'tags tags-active': 'tags' " v-for="tag in newsTags" :key="tag.id"
                   @click="setSelectedTag(tag.id)">
                {{ tag.title }}
              </div>
            </div>
          </div>

          <h1 class="font-bold text-[19px] my-6">Шинээр нэмэгдсэн</h1>
          <div class="blog1 grid grid-cols-3 gap-x-12 gap-y-8">
            <div class="item" v-for="(item) in news" :key="item.id">
              <div class="is-free" v-if="item.is_free === 1">Үнэгүй</div>
              <div class="img" style="border: 1px solid red">
                <div class="img-cover" :style="`background-image: url('${imageUrl(item.image)}')`"></div>

              </div>
              <div class="wrapper">
                <div class="date"><a href="javascript:void(0)">{{ item.created_at }}</a></div>
                <div class="con">
                  <div class="category">
                    <a href="#" v-for="t in item.tags" :key="t.id"><i class="ti-user"></i>{{ t.title }}</a>
                  </div>
                  <div class="text"><a href="javascript:void(0)" @click="detail(item)">{{ item.title }}</a></div>
                  <a href="javascript:void(0)" @click="detail(item)" class="icon-btn">
                    <i class="isax isax-arrow-right-1"></i></a>
                </div>
              </div>
            </div>
          </div>

          <!--          <div class="grid grid-cols-4 gap-6">-->
          <!--            <div v-if="newsLoading"-->
          <!--                 class="col-span-3 flex justify-center items-center animate-pulse bg-gray-200"></div>-->
          <!--            <div v-else-if="news.length > 0" class="col-span-3">-->
          <!--              <div class="news-list">-->
          <!--                <div class="news-latter-list"-->
          <!--                     v-for="(item) in news"-->
          <!--                     :key="item.id" @click="detail(item)">-->
          <!--                  <div class="img">-->
          <!--                    <img :src="imageUrl(item.image)" alt=""-->
          <!--                         class="news-latter-img-big"/>-->
          <!--                  </div>-->
          <!--                  <div class="news-latter-content">-->
          <!--                    <div class="news-latter-title">-->
          <!--                      <h1>{{ item.title }}</h1>-->
          <!--                    </div>-->
          <!--                    <div class="news-latter-desc"><p>{{ item.description }}</p></div>-->
          <!--                    <div class="news-latter-tag">-->
          <!--                      <div class="tags" v-for="t in item.tags" :key="t.id">-->
          <!--                        {{ t.title }}-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->

          <!--                </div>-->
          <!--                <div style="display:flex; justify-content:center">-->
          <!--                  <button @click="prevPage" :disabled="currentPage === 1">Өмнөх</button>-->
          <!--                  <span style="margin-left:20px; margin-right:20px">{{ currentPage }}</span>-->
          <!--                  <button :style="{ color: lastPageColor }" @click="nextPage" :disabled="currentPage === totalPages">-->
          <!--                    Дараах-->
          <!--                  </button>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div v-else class="col-span-3 flex justify-center items-center news-latter-list"><h1> Хоосон байна</h1>-->
          <!--            </div>-->


        </div>
      </div>
    </div>
  </div>
  <div>

    <Dialog v-model:visible="visible" :header="null"
            :style="{ width: '80%',backgroundColor:' rgba(0, 0, 0, 0.4)' }" class="modal-news-latter-detail">
      <div class="news-latter-detail" v-if="newsDetail">
        <div class="detail-container">
          <!--          <h1 class="title" v-if="newsDetail.title">{{newsDetail.title}}</h1>-->
          <img :src="imageUrl(newsDetail.image)" alt="" v-if="newsDetail.image"
               class="image"/>
          <div class="news-latter-tag" v-if="newsDetail.tags.length>0">
            <div class="tags" v-for=" t in newsDetail.tags" :key="t.id">
              {{ t.title }}
            </div>
          </div>
          <div class="description" v-html="newsDetail.body" v-if="newsDetail.body"></div>
        </div>

      </div>
    </Dialog>

  </div>
  <ConfirmDialog></ConfirmDialog>
  <Dialog :showHeader="false" class="login-page-modal" v-model:visible="isAuthVisible" :modal="true"
          :dismissableMask="true"
          position="center" :closable="true" :style="{width: '600px'}">
    <Login :hide="hideAuthModal"/>
  </Dialog>
</template>

<script>
import axios from "axios";
import config from "@store/_boot/config";
import {imageUrl} from "@utils";
import moment from 'moment';
import {mapGetters} from "vuex";
import Login from "@pages/auth/login.vue";


export default {
  name: 'NewsLatter',
  components: {Login},
  data() {
    return {
      isAuthVisible: false,
      visible: false,
      isLoading: true,
      isLoadingMore: false,
      hasMore: false,
      page: 1,
      news: [],
      newsSpecial: [],
      newsPerPage: '',
      totalNews: 0,
      currentPage: 1,
      lastPage: 0,
      newsDetail: null,
      newsTags: [],
      selectedTag: null,
      newsLoading: false,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
    };
  },

  created() {
    this.getNews();
    this.getNewsSpecial();
    this.getNewsCategory();
  },
  computed: {
    ...mapGetters(['user']),
    totalPages() {
      return Math.ceil(this.totalNews / this.newsPerPage);
    },
    lastPageColor() {
      return this.lastPage === this.currentPage ? 'grey' : '';
    },

  },
  methods: {
    showAuthModal() {
      this.isAuthVisible = true;
    },
    hideAuthModal() {
      this.isAuthVisible = false;
    },
    expireConfirmDialog() {
      this.$confirm.require({
        message: 'Та эрхээ сунгаж тус контентыг үзнэ үү !!',
        header: 'Эрх сунгах',
        rejectProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true
        },
        acceptProps: {
          label: 'Save'
        },
        acceptLabel: "Эрх сунгах",
        rejectLabel: "Болих",
        accept: () => {
          this.$router.push('/payment');
        },
      });
    },
    setSelectedTag(id) {
      this.selectedTag = id
      this.getNews()
    },
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    getNewDetail(id) {
      axios.get(`${config.HOST}/api/web/all-news-latter/${id}?page=${this.currentPage}`).then(({data}) => {

        // console.log('data', data)
        this.newsDetail = data;

      })
    },
    imageUrl,
    getNewsSpecial() {
      axios.get(`${config.HOST}/api/web/all-news-latter-special?page=${this.currentPage}`).then(({data}) => {
        if (data.status) {
          // console.log('data', data)
          this.newsSpecial = data.data.data;
          // this.totalNews = data.data.total;
          // this.currentPage = data.data.current_page;
          // this.newsPerPage = data.data.per_page;
          // this.lastPage = data.data.last_page;
          // console.log("news-latter----->", this.news)
          // console.log(" this.totalnews-latter----->", this.totalNews)
          // console.log(" this.currentPage----->", this.currentPage)
          // console.log("this.newsPerPage----->", this.newsPerPage)
        }
      })
    },

    getNews() {
      this.newsLoading = true
      axios.get(`${config.HOST}/api/web/all-news-latter?tag_id=${this.selectedTag}&page=${this.currentPage}`).then(({data}) => {
        if (data.status) {
          // console.log('data', data)
          this.news = data.data.data;
          this.totalNews = data.data.total;
          this.currentPage = data.data.current_page;
          this.newsPerPage = data.data.per_page;
          this.lastPage = data.data.last_page;

          this.newsLoading = false

        }
      })
    },
    getNewsCategory() {
      axios.get(`${config.HOST}/api/web/news-latter-tags`).then(({data}) => {

        if (data) {
          console.log('data', data)
          this.newsTags = data
        }
        // console.log('data',data)
      })
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        console.log("dsvssdvsdv------>")
        this.currentPage++;
        this.getNews();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getNews();
      }
    },
    detail(item) {
      console.log("detail item", item.is_free, item.title)
      if (this.user == null) {
        this.showAuthModal()
      } else {
        if (item?.is_free === 1) {
          this.getNewDetail(item.id)
          this.visible = true;
        } else {
          if (this.user.days === 0) {
            this.expireConfirmDialog()
          } else {
            this.getNewDetail(item.id)
            this.visible = true;
          }
        }
      }
    }
  },

};
</script>

