<template>
  <div class="lg:mt-[-90px]">

    <div class="bg-cover bg-top bg-center h-[160px] sm:h-[160px] md:h-[300px] lg:h-[500px]"
         style="background-image: url('https://btvplus.mn/btv/img/cbg.9af44806.webp')"></div>
    <div class="hero text-white">
      <div class="flex flex-wrap md:container md:mx-auto mt-[-30px] sm:mt-[-30px] md:mt-[-80px] lg:mt-[-100px] md:w-[96vw] lg:w-[75vw]">
        <div class="w-full sm:w-1/1 md:w-1/4 lg:w-1/4 px-2 mb-3">
          <div class="shadow-lg bg-[#121212] max-w-sm rounded-lg" style="text-align: -webkit-center">
            <div class="avatar justify-center p-6 pb-0">
              <div class="rounded-full w-24 h-24">
                <img :src="user.avatar == null ? 'https://btvplus.mn/assets/lambda/images/avatar.png' : user.avatar" class="rounded-full">
              </div>
            </div>
            <div class="w-full h-18 p-4 text-center desc-txt">
              <p class="font-bold group-hover:text-accent">{{ user.first_name == null ? user.nickname : user.first_name }}</p>
              <p class="p-0 main-text">Таны ID:{{ user.id }}</p>
            </div>
            <div class="info-row">
              <div class="grid grid-cols-2 gap-2">

                <div class="col info-box">
                  <i class="isax isax-timer"></i>
                  <div class="info-box-child">
                    <strong>{{ user.days }} өдөр</strong>
                  </div>
                </div>

                <a href="/#/payment" class="col info-box">
                  <i class="isax isax-wallet-2"></i>
                  <div class="info-box-child">
                    <strong> Эрх сунгах</strong>
                  </div>
                </a>
              </div>
            </div>


            <!--              mobile-->

            <div class="md:hidden text-justify">
              <div class="horizontal phonemenu">
                <router-link to="/profile/">
                  <i class="uil uil-user"></i>
                  <!--                  <span>Хувийн мэдээ</span>-->
                </router-link>
                <router-link to="/profile/change-password">
                  <i class="uil uil-unlock-alt"></i>
                  <!--                  Пин солих-->
                </router-link>
                <router-link to="/profile/my-fund">
                  <i class="uil uil-database-alt"></i>
                  <!--                  Таалагдсан контент-->
                </router-link>
                <router-link to="/profile/viewed-history">
                  <i class="uil uil-history"></i>
                  <!--                  Үзсэн түүх-->
                </router-link>
                <router-link to="/user/logout">
                  <i class="uil uil-backspace"></i>
                  <!--                  Гарах-->
                </router-link>
              </div>
<!--              <ul class="menu items-stretch px-3 horizontal phonemenu">-->
<!--                <li>-->
<!--                  <router-link to="/profile/">-->
<!--                    <i class="uil uil-user"></i>-->
<!--                    &lt;!&ndash;                                        <span>Хувийн мэдээ</span>&ndash;&gt;-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <router-link to="/profile/change-password">-->
<!--                    <i class="uil uil-unlock-alt"></i>-->
<!--                    &lt;!&ndash;                                        <span>Пин солих</span>&ndash;&gt;-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <router-link to="/profile/my-fund">-->
<!--                    <i class="uil uil-database-alt"></i>-->
<!--                    &lt;!&ndash;                                        <span>Миний сан</span>&ndash;&gt;-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li>-->
<!--                  <router-link to="/profile/viewed-history">-->
<!--                    <i class="uil uil-history"></i>-->
<!--                    &lt;!&ndash;                                        <span>Үзсэн түүх</span>&ndash;&gt;-->
<!--                  </router-link>-->
<!--                </li>-->
<!--              </ul>-->
            </div>

            <!--              desktop-->
            <div class="hidden md:block text-justify">
              <div class="deskmenu">
                <router-link to="/profile/">
                  <i class="uil uil-user"></i>
                  Хувийн мэдээ
                </router-link>
                <router-link to="/profile/change-password">
                  <i class="uil uil-unlock-alt"></i>
                  Пин солих
                </router-link>
                <router-link to="/profile/my-fund">
                  <i class="uil uil-database-alt"></i>
                  Таалагдсан контент
                </router-link>
                <router-link to="/profile/viewed-history">
                  <i class="uil uil-history"></i>
                  Үзсэн түүх
                </router-link>
<!--                <router-link to="javascript:void(0)" @click="logout">-->
<!--                  <i class="uil uil-backspace"></i>-->
<!--                  Гарах-->
<!--                </router-link>-->
                <a href="javascript:void(0)" class="close-btn" @click="logout">
                  <i class="uil uil-backspace"></i>
                  Гарах
                </a>
              </div>
            </div>
          </div>
        </div>
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Profile',
  components: {},
  data() {
    return {
      activeName: 'first',
      selected: 1
    };
  },

  computed: {
    ...mapGetters(['isLoadingAuth', 'user'])
  },

  created() {
    console.log('user information');
    console.log(this.user);
  },

  methods: {
    ...mapActions(['logout']),
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    select(i) {
      this.selected = i;
    }
  },
};
</script>

<style>
.deskmenu li a {
  /*@apply hover:border-r-4 border-accent;*/
}
.phonemenu li a {
  /*@apply hover:border-b-4 border-accent;*/
}
</style>
