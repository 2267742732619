<template>

  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="bg-base-100 p-6 bg-[#121212] rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-unlock-alt"></i>
        Пин солих
      </header>
      <form class="card-body w-full p-6" :rules="rules" ref="ruleForm" :model="ruleForm">
        <div v-if="step === 1">
          <div class="items-center flex justify-center mb-6">
            <!--          <div class="md:w-1/3">-->
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Утасны дугаар: {{ ruleForm.phone }} </span>
            </label>
            <!--          </div>-->
            <!--          <div class="md:w-1/3">-->
            <!--            <input type="number" placeholder="Утасны дугаар" disabled v-model="ruleForm.phone"-->
            <!--                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form">-->
            <!--          </div>-->
            <!--          <div class="md:w-1/3">-->
            <Button :disabled="isCounting" class="bg-[#2e2d2d] hover:bg-[#e67e22]" :class="isLoading ? 'loading' : ''" @click="keySendPassEnter">
              <div class="text-center w-full">Код илгээх<span> {{isCounting ? ` ${secondsLeft}` : "" }}</span></div>
            </Button>
            <!--          </div>-->
          </div>
          <div class="verify-number flex justify-center pt-2 mb-6">
            <CodeInputVerify
                ref="pin"
                :fields="4"
                :fieldWidth="38"
                type="number"
                v-on:change="onChangePin"
                v-on:complete="onCompletePin"
            />
          </div>
          <div class="flex justify-center">
            <Button :disabled="!isCounting" class="mt-5 bg-[#e67e22] hover:bg-[#E67E22FF]" :class="isLoadingVerify ? 'loading' : ''" @click="changeStep">
              <div class="text-center w-full">Үргэжлүүлэх</div>
            </Button>
          </div>
        </div>
        <div v-if="step === 2">
          <div class="items-center flex justify-center mb-6">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Шинэ пин кодоо оруулна уу</span>
            </label>
          </div>
          <div class="verify-number flex justify-center pt-2 mb-6">
            <CodeInputVerify
                ref="newPin"
                :fields="4"
                :fieldWidth="38"
                type="number"
                v-on:change="onChangeNewPin"
                v-on:complete="onCompleteNewPin"
            />
          </div>
          <div class="flex justify-center">
            <Button :disabled="disabled" class="mt-5 bg-[#e67e22] hover:bg-[#E67E22FF] flex items-center" :class="isLoadingVerify ? 'loading' : ''" @click="changePin()">
              <div class="text-center w-full">Пин хадгалах</div>
            </Button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import CodeInputVerify from "@components/CodeInputVerify.vue";

export default {
  components: {
    CodeInputVerify
  },
  data() {
    return {
      ruleForm: {
        phone: '',
        pin: '',
        newPin: '',
      },
      secondsLeft: 120,
      disabled: true,
      isCounting: false,
      timer: null,
      step: 1,
      isLoading: false,
      isLoadingVerify: false,
    };
  },

  computed: {
    ...mapGetters(['user']),
  },

  created() {
    this.ruleForm.phone = this.user.phone;
  },

  methods: {
    ...mapActions(['checkSendPhone', 'changePass', 'checkPin']),
    changeStep() {
      console.log('user' + this.user.phone);
      this.checkPin(({vm: this}));
    },
    keySendPassEnter() {
      console.log('user' + this.user.phone);
      if (!this.isCounting) {
        this.isCounting = true;

        this.timer = setInterval(() => {
          if (this.secondsLeft > 0) {
            this.secondsLeft--;
          } else {
            this.stopCountdown();
          }
        }, 1000);
      }
      this.checkSendPhone(({vm: this}))
    },

    stopCountdown() {
      clearInterval(this.timer);
      this.isCounting = false;
      this.secondsLeft = 60;
    },
    onChangePin(pin) {
      if (pin.length === 4) {
        this.ruleForm.pin = pin;
      }
    },

    onCompletePin(val) {
      this.ruleForm.pin = val;
    },

    onChangeNewPin(newPin) {
      if (newPin.length === 4) {
        this.ruleForm.newPin = newPin;
      }
    },

    onCompleteNewPin(val) {
      if (val.length === 4) {
        this.ruleForm.newPin = val;
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    changePin() {
      console.log('user' + this.user.phone);
      this.changePass(({vm: this}));
    },
  },
};
</script>
