<template>
  <div class="header z-50 w-full transition duration-700 header__overlay">
    <!--    <div class="header-mid">-->
    <!--      <div class="container">-->
    <!--        <div class="relative z-10 flex items-center justify-between w-full h-full">-->
    <!--          <div class="header-left">-->
    <!--            <router-link to="/" class="nav-logo">-->
    <!--              <img src="/img/logo-white.png" alt="logo" class="large-logo"/>-->
    <!--            </router-link>-->
    <!--          </div>-->
    <!--          <div class="header-mid-actions">-->
    <!--            <a href="javascript:void(0)">-->
    <!--              <i class="isax isax-notification"></i>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--          <auth/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <nav class="header-nav" :class="[isFixed && 'fixed']">
      <div class="container">
        <router-link to="/" class="fixed-nav-logo" data-aos="fade-down-right">
          <img src="/img/logo-white.png" alt="logo" class="large-logo"/>
        </router-link>

        <router-link to="/">
          <i class="isax isax-home"></i>
          <span>Нүүр</span>
        </router-link>

        <div class="mega-nav">
          <a href="javascript:void(0)">
            <i class="isax isax-video-circle"></i>
            <span>Контент</span>
          </a>
          <ul class="mega">
            <li>
              <div class="mega-menu">
                <div class="tab-container">
                  <div class="row">
                    <div class="col-3">
                      <div class="tab-btn-wrap">
                      <router-link v-for="(item, index) in categories" :key="index" :to="`/series/${item.id}/all/all/z-a`" class="tab-btn" :data-target="`#tab${item.id}`">
                        {{item.title}}
                      </router-link>
                      </div>

                      <router-link :to="`/series/all/all/all/z-a`" class="tab-btn all">
                        <span>Бүх контент</span>
                        <i class="isax isax-arrow-right-1"></i>
                      </router-link>
                    </div>
                    <div class="col-9">
                      <div v-for="(item, index) in categories" :key="index" class="tab-content" :id="`tab${item.id}`">
                        <h3>{{ item.title }}</h3>
                        <div class="nav-content grid gap-3 grid-cols-3">
                          <div v-for="(c, index) in item.medias" :key="index">
                            <router-link :to="`/theatre/detail/${c.id}`" class="nav-content-item">
                              <Image :src="c.poster_hz" />
                              <span>{{ c.title }}</span>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <a href="https://bloombergtv.mn" target="_blank">
          <i class="isax isax-global"></i>
          <span>Мэдээ</span>
        </a>
        <router-link to="/news-latter">
          <i class="isax isax-archive-book4"></i>
          <span>Товхимол</span>
        </router-link>
        <router-link to="/podcast">
          <i class="isax isax-microphone"></i>
          <span>Подкаст</span>
        </router-link>
        <div class="spacer"></div>

        <div class="nav-right">
          <router-link to="/live" class="live-nav">
            <span class="live-dot"></span>
            <span>LIVE</span>
          </router-link>
          <search/>
          <div class="auth-fixed" data-aos="fade-down-right">
            <auth/>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Logo from "../../assets/logo.png";
import LogoDark from "../../assets/animax-logo-dark.png";
import {ref} from "vue";
import useHeaderRoute from "@hooks/useHeaderRoute";
import Search from "../Seeroo/Search.vue";
import Auth from "../Auth.vue";
import LogoMini from "../../assets/logo.png";
import {mapActions, mapGetters} from 'vuex';
import Image from "../../components/Image.vue"


export default {
  setup() {
    const {currentRoute, routes} = useHeaderRoute();
    const isTop = ref(true);
    return {
      currentRoute,
      routes,
      isTop,
    };
  },

  components: {
    Search,
    Auth,
    Image
  },

  data() {
    return {
      visibleUserMenu: false,
      LogoMini,
      Logo,
      LogoDark,
      isFixed: false,
      categories: window.init.categories
    };
  },

  computed: {
    ...mapGetters(['showMenu', 'user'])
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    let btns = document.querySelectorAll(".tab-btn");
    let tabContents = document.querySelectorAll(".tab-content");
    btns.forEach((btn) => {
      btn.addEventListener("mouseover", () => {
        btns.forEach((btn) => btn.classList.remove("active"));
        tabContents.forEach((tabContent) => tabContent.classList.remove("active"));
        btn.classList.add("active");
        document.querySelector(btn.dataset.target).classList.add("active");
      });
    });
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions(['toggleSidebar']),
    handleScroll() {
      this.isTop = window.scrollY === 0;
      if (window.scrollY > 100) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },

    // scrollToElement() {
    //   const el = document.getElementById('live');
    //
    //   if (el) {
    //     // Use el.scrollIntoView() to instantly scroll to the element
    //     el.scrollIntoView({behavior: 'smooth'});
    //   }
    // }
  },
};
</script>

<style>
.header__overlay {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(10%, rgba(12, 18, 26, 0.7)),
      color-stop(10%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
      top,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
      top,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
      to bottom,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
}


</style>
