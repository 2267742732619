<template>
  <div class="relative text-slate-100 leading-normal text-[12px] font-light cursor-pointer" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
    <span>{{ truncatedTitle(content_episode_title) }}</span>
    <div v-if="showTooltip" class="absolute bg-[#333] text-white py-[7px] px-[10px] rounded-[4px] top-[100%] left-0 z-[10] w-auto">{{ content_episode_title }}</div>
  </div>
</template>
<script>
export default {
   props: ['content_episode_title'],
   data() {
    return {
      showTooltip: false
    };
  },
  methods: {
    truncatedTitle() {
      if(!this.content_episode_title) return null;
      const maxLength = 30;
      return this.content_episode_title.length > maxLength
        ? this.content_episode_title.slice(0, maxLength) + '...'
        : this.content_episode_title;
    }
  }
}
</script>