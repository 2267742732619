<template>

  <!--          Миний сан-->
  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="p-6 bg-[#121212] rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-database-alt"></i>
        Миний сан
      </header>
      <div class="series-container p-4">
        <div class="divider mt-0 after:bg-gray-500 before:bg-gray-500"></div>
        <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4 overflow-y-scroll" v-if="contents.length > 0">

          <!-- <div v-for="media in contents" :key="media.id" class="media-item">
            <div v-if="media.media_type === 'anime'">
              <h3>{{ media.anime_title }}</h3>
              <img :src="media.anime_image" alt="Anime Image" />
            </div>
            <div v-else-if="media.media_type === 'ep'">
              <h3>{{ media.episode_title }}</h3>
              <p>Episode {{ media.episode_number }}</p>
            </div>
          </div> -->
          <li class="related-tiles flex justify-center" v-for="content in contents" :key="content.type + content.id">
            <a href="javascript:void(0)" class="related-tile" v-if="content.type == 'anime'">
              <div class="related-social">
                <button
                    class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
                  <i class="uil-share-alt"></i>
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 hover:bg-[#909399] hover:border-transparent">
                  13+
                </button>
              </div>
              <img src="https://picsum.photos/id/1005/260/400">
              <div class="related-details">
                <span class="related-title">1-р анги</span>
                <span class="related-info">Quisque vel felis lectus donec vitae dapibus magna</span>
              </div>
            </a>

            <a href="javascript:void(0)" class="related-tile" v-else @click="gotoWatch(content.episode_anime_id, content.episode_id)">
              <div class="related-social">
                <button class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1 ">
                {{ content.updated_at }}
                </button>
                <button
                    class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mb-1">
                  {{ content.episode_number }}-р анги
                </button>
              </div>
              <img :src="content.episode_image ? 'https://btvplus.mn' + content.episode_image : 'https://picsum.photos/id/1005/260/400'">
              <div class="related-details">
                <span class="related-title">{{ content.episode_anime_title }}</span>
                <span class="related-info">{{ content.episode_title }}</span>
              </div>
            </a>
          </li>
        </ul>
        <div class="w-full flex justify-center items-center mt-12" v-else>
          <div class="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-[#e67e22]" />
        </div>
        <div v-if="!hasMore" class="mt-12 text-center">
          Контент дууссан байна
         </div>
      </div>
    </div>
  </div>


  <!--    <div class="profile-div">-->
  <!--        <div class="profile-card">-->
  <!--            <div class="normal-header">-->
  <!--                <span class="user-name"><i class="el-icon-film"></i><span class="title-left">Миний сан</span></span>-->
  <!--            </div>-->
  <!--            <div class="card-content">-->
  <!--                <div>-->
  <!--                    <div v-if="isLoading">-->
  <!--                        <my-fund-placeholder></my-fund-placeholder>-->
  <!--                    </div>-->

  <!--                    <div v-else class="collections-wrap is-active">-->
  <!--                        <div class="archive">-->
  <!--                            <article class="article" v-for="item in episodes" :key="item.index">-->
  <!--                                <div class="series-container">-->
  <!--                                    <div>-->
  <!--                                        <div class="related-tiles">-->
  <!--                                            <a :href="`/#/watch/series/episode/`+item.id" class="related-tile">-->
  <!--                                                <div class="related-social">-->
  <!--                                                    <el-button size="mini" class="x-mini" type="primary" icon="el-icon-share"></el-button>-->
  <!--                                                    <el-tag size="mini" effect="dark" type="info" v-show="item.age_limit!= null">{{ item.age_limit }}</el-tag>-->
  <!--                                                </div>-->
  <!--                                                <img-->
  <!--                                                    src="https://animax.mn/assets/max/images/poster.png"-->
  <!--                                                    v-lazy:background-image="$helper.fixImagePath(item.image)"-->
  <!--                                                    class="poster"-->
  <!--                                                />-->
  <!--                                                <div class="related-details"  v-if="$app == 'wemax'">-->
  <!--                                                    <span class="related-title"  v-if="$app == 'wemax'">{{ item.title }}</span>-->
  <!--                                                    <span class="related-info" v-html="item.description"></span>-->
  <!--                                                </div>-->
  <!--                                            </a>-->
  <!--                                        </div>-->
  <!--                                    </div>-->
  <!--                                </div>-->
  <!--                            </article>-->
  <!--                        </div>-->

  <!--                        <div class="load-more-wrap has-text-centered">-->
  <!--                            <loader v-if="isLoadingMore"></loader>-->
  <!--                            <a-->
  <!--                                v-else-->
  <!--                                href="javascript:void(0)"-->
  <!--                                @click="getEpisodes(page, true)"-->
  <!--                                class="load-more-button"-->
  <!--                            >Цааш үзэх</a-->
  <!--                            >-->
  <!--                        </div>-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</template>

<script>

// import Loader from "../../components/loader.vue";
// import MyFundPlaceholder from "../../components/placeholders/my-fund-placeholder.vue";
import {mapActions} from "vuex";
import { mapGetters } from "vuex/dist/vuex.cjs.js";
// import axios from "axios";
import api from '../../store/_boot/baseApi';

export default {
  name: 'MyFund',
  props: ["url", "hlink"],
  components: {
    // Loader,
    // MyFundPlaceholder,
  },
  data() {
    return {
      page: 1,
      perPage: 100,
      isLoading: false,
      hasMore: true,

      isLoadingMore: false,
      contents: []
    };
  },

  computed: {
    ...mapGetters(['user'])
  },

  created() {
    this.getLikedContents();
  },

  methods: {
    ...mapActions(["setEpisode", "gotoTheatre", "getEpisodes", "getAnime", "setBackLink"]),

    async getLikedContents() {
      if (this.isLoading || !this.hasMore) return;
      this.isLoading = true;
      try {
        const response = await api.get("/api/profile/my-fund/" + this.user.id, {
          params: {
            page: this.page,
            perPage: this.perPage,
          },
        });

        const { data } = response;
        if (data.data.length > 0) {
          this.contents.push(...data.data);
          this.page++;
        } else {
          this.hasMore = false;
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.isLoading = false;
      }
    },

    gotoWatch(animeId, epId) {
      console.log('epId',epId)
      this.setBackLink(this.$route.path);
      this.$router.push(`/theatre/detail/${animeId}`);
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 500) {
        this.getLikedContents();
      }
    },
  },
  mounted() {
    this.getLikedContents();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
