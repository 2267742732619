<template>

  <!--          Үзсэн түүх-->
  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="p-6 bg-[#121212] rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-history"></i>
        Үзсэн түүх
      </header>

        <div class="w-full flex justify-center items-center mt-12" v-if="contents.length == 0">
          <div class="border-gray-300 h-10 w-10 animate-spin rounded-full border-4 border-t-[#e67e22]" />
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-5" v-else>
          <div
            class="bg-[#1F2027FF] mt-3 " 
            v-for="(content, i) in contents"
            :key="content.title + i + content.id">
          <div class="flex h-[100px] justify-between shadow-sm rounded-lg lg:h-[150px] relative">
            <div class="flex-shrink-0 flex-1 min-w-[100px]">
              <img 
                class="object-cover w-full h-full rounded-l-lg" 
                :src="content.image ? `https://btvplus.mn${content.image}` : 'https://images.unsplash.com/photo-1725610588086-b9e38da987f7?q=80&w=1200'" 
                alt=""
              >
            </div>

            <div class="flex-[3] py-3 px-4">
              <h6 class="text-slate-600 text-[15px] truncate">
                {{ content.title }}
              </h6>  
              <p class="text-slate-100 leading-normal text-[13px] font-light text-[#e67e22] truncate">
                {{ formatDate(content.created_at) }}
              </p>
              <tooltip :content_episode_title="content.episode_title" />
            </div>
          </div>

          <div :class="`w-[90%] h-1 mt-[4px] rounded bg-red-500`"></div>  
        </div>
    </div>
    <div v-if="!hasMore" class="mt-12 text-center">
              Контент дууссан байна
    </div>
    </div>
  </div>

  <!--    <div class="profile-div">-->
  <!--        <div class="profile-card">-->
  <!--            <div class="normal-header">-->
  <!--                <span class="user-name"><i class="el-icon-collection"></i><span class="title-left">Үзсэн түүх</span></span>-->
  <!--            </div>-->
  <!--            <div class="card-content">-->

  <!--                <div v-if="isLoading">-->
  <!--                    <viewed-history-placeholder></viewed-history-placeholder>-->
  <!--                </div>-->
  <!--                <div v-else class="pro-container">-->
  <!--                    <ul>-->
  <!--                        <h3>Yesterday</h3>-->
  <!--                        <li v-for="item in episodes" :key="item.index">-->
  <!--                            <a  :href="`/#/watch/series/episode/`+item.id" class="profile-list-main">-->
  <!--                                <img-->
  <!--                                    src-placeholder="/assets/max/images/bg-loading.gif"-->
  <!--                                    src="https://animax.mn/assets/max/images/poster-horz.png"-->
  <!--                                    v-lazy:background-image="$helper.fixImagePath(item.image1)"-->
  <!--                                    class="poster"-->
  <!--                                />-->
  <!--                                <div class="viewed-video-info">-->
  <!--                                    <div class="viewed-video-title">{{ item.title }}</div>-->
  <!--                                    <div class="viewed-video-sub">{{ item.created_at }}</div>-->
  <!--                                    <div class="viewed-video-desc" v-html="item.description"></div>-->
  <!--                                </div>-->
  <!--                                <div class="viewed-video-button">-->
  <!--                                    <div class="viewed-video-social">-->
  <!--                                        <el-button size="mini" class="x-mini" type="primary" icon="el-icon-share"></el-button>-->
  <!--                                        <el-tag size="mini" effect="dark" type="info" v-show="item.age_limit!= null">{{ item.age_limit }}</el-tag>-->
  <!--                                    </div>-->
  <!--                                    <div class="viewed-video-del">-->
  <!--                                        <el-button @click="toggle()" class="x-mini-trush" icon="el-icon-delete"></el-button>-->
  <!--                                    </div>-->
  <!--                                </div>-->
  <!--                            </a>-->
  <!--                        </li>-->
  <!--                    </ul>-->
  <!--                    <div class="load-more-wrap has-text-centered">-->
  <!--                        <loader v-if="isLoadingMore"></loader>-->
  <!--                        <a-->
  <!--                            v-else-->
  <!--                            href="javascript:void(0)"-->
  <!--                            @click="getEpisodes(page, true)"-->
  <!--                            class="load-more-button"-->
  <!--                        >Цааш үзэх</a-->
  <!--                        >-->
  <!--                    </div>-->
  <!--                </div>-->

  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</template>

<script>

// import Loader from "../../components/loader.vue";
// import ViewedHistoryPlaceholder from "../../components/placeholders/viewed-history-placeholder.vue";
// import {mapGetters, mapActions} from "vuex";
import { mapGetters } from 'vuex';
import api from '../../store/_boot/baseApi';
import Tooltip from "./tooltip.vue";
import moment from 'moment';

export default {
  name: 'ViewedHistory',
  props: ["url", "hlink"],
  components: {Tooltip},
  data() {
    return {
      page: 1,
      perPage: 100,
      isLoading: false,
      hasMore: true,

      isLoadingMore: false,
      contents: []
    };
  },

  computed: {
    ...mapGetters(['user'])
  },

  created() {
    this.getLikedContents();
  },

  methods: {
    // ...mapActions(["setEpisode", "gotoTheatre", "getEpisodes", "getAnime", "setBackLink"]),

    toggleItem() {
      this.toggle = !this.toggle;
    },

    async getLikedContents() {
      if (this.isLoading || !this.hasMore) return;
      this.isLoading = true;
      try {
        const response = await api.get("/api/profile/my-history/" + this.user.id, {
          params: {
            page: this.page,
            perPage: this.perPage,
          },
        });

        const { data } = response;
        if (data.data.length > 0) {
          this.contents.push(...data.data);
          this.page++;
        } else {
          this.hasMore = false;
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.isLoading = false;
      }
    },


    gotoWatch(id) {
      this.setBackLink(this.$route.path);
      this.$router.push(`/watch/episode/${id}`);
    },
    formatDate(date){
      return moment(date).locale('mn').format('ll')
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 500) {
        this.getLikedContents();
      }
    },
  },
  mounted() {
    this.getLikedContents();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
