<template>
  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="p-6 bg-[#121212] rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-user"></i>
        Хувийн мэдээлэл
<!--        <label class="btn btn-sm btn-outline btn-accent bg-[#0000008f] min-w-[148px]">-->
<!--            <i class="uil uil-image-v"></i>-->
<!--            <span class="pl-1 normal-case">Change cover</span>-->
<!--            <input type='file' class="hidden"/>-->
<!--        </label>-->
      </header>
      <form class="card-body w-full" :model="ruleForm" :rules="rules" ref="ruleForm">

<!--        <div class="absolute right-0">-->
<!--          <div class="fixed justify-end mt-[-560px] sm:mt-[-560px] md:mt-[-140px] ml-[-160px] md:ml-[-260px]">-->
<!--            <label class="btn btn-sm btn-outline btn-accent bg-[#0000008f] min-w-[148px]">-->
<!--              <i class="uil uil-image-v"></i>-->
<!--              <span class="pl-1 normal-case">Change cover</span>-->
<!--              <input type='file' class="hidden"/>-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
        <div class="md:flex md:items-center mb-6 pt-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Зураг:</span>
            </label>
          </div>
          <div class="md:w-2/3">
<!--            <FileUpload-->
<!--                name="image"-->
<!--                :url="`${config.HOST}/lambda/krud/upload`"-->
<!--                accept="image/*"-->
<!--                customUpload-->
<!--                :auto="false"-->
<!--                @select="onSelect"-->
<!--            >-->
<!--            </FileUpload>-->
            <div style="width: 90%">
              <FileUpload
                  name="avatar"
                  accept="image/*"
                  customUpload
                  :auto="false"
                  chooseLabel="Зураг сонгох"
                  :showUploadButton="false"
                  uploadLabel="Хадгалах"
                  cancelLabel="Болих"
                  :maxFileSize="2000000"
                  @select="onSelect"
                  @upload="onUpload"
              >
              </FileUpload>
            </div>
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Нэр:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="Таны нэр"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.firstname">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Овог:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="Таны овог"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.lastname">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">И-мэйл:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="И-мэйл хаяг"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.email">
          </div>
        </div>
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-1/3">
            <label class="block md:text-right mb-1 md:mb-0 pr-4">
              <span class="label-text">Утас:</span>
            </label>
          </div>
          <div class="md:w-2/3">
            <input type="text" placeholder="Утасны дугаар"
                   class="input input-bordered hover:border-accent w-full md:w-3/4 email-form" v-model="ruleForm.phone">
          </div>
        </div>
<!--        <div class="md:flex md:items-center mb-6">-->
<!--          <div class="md:w-1/3">-->
<!--            <label class="block md:text-right mb-1 md:mb-0 pr-4">-->
<!--              <span class="label-text">Хүйс:</span>-->
<!--            </label>-->
<!--          </div>-->
<!--          <div class="md:w-2/3">-->
<!--            <div class="w-1/2 flex gap-4 w-full md:w-3/4">-->
<!--              <label class="cursor-pointer label basis-1/2">-->
<!--                <input type="radio" name="gender" checked="checked"-->
<!--                       class="radio radio-accent radio-xs" value="" v-model="ruleForm.gender">-->
<!--                <span class="label-text pl-2">Эрэгтэй</span>-->
<!--              </label>-->
<!--              <label class="cursor-pointer label basis-1/2">-->
<!--                <input type="radio" name="gender" class="radio radio-accent radio-xs"-->
<!--                       value="" v-model="ruleForm.gender">-->
<!--                <span class="label-text pl-2">Эмэгтэй</span>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="md:flex md:items-center">
          <div class="md:w-2/3"></div>
          <div class="w-full">
            <Button :loading="isLoading" class="mt-5 bg-[#e67e22] hover:bg-[#E67E22FF]" @click="submitForm">
              <div class="text-center w-full">Хадгалах</div>
            </Button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import config from "../../store/_boot/config.js";
import FileUpload from 'primevue/fileupload';
export default {
  name: 'ProfileForm',
  components: {
    FileUpload: FileUpload
  },
  data() {
    return {
      config: config,
      ruleForm: {
        userId: '',
        coverImage: '',
        avatar: null,
        firstname: '',
        lastname: '',
        nickename: '',
        phone: '',
        gender: '',
      },
      isLoading: false,
      rules: {
        firstname: [
          { required: true, message: "Нэрээ оруулна уу!", trigger: "blur" },
          { min: 3, max: 50, message: "Нэр 3-50 тэмдэгт орсон байх ёстой.", trigger: "blur" },
        ],
        lastname: [
          { required: true, message: "Овгоо оруулна уу!", trigger: "blur" },
          { min: 3, max: 50, message: "Овог 3-50 тэмдэгт орсон байх ёстой.", trigger: "blur" },
        ],
        email: [
          { required: true, message: "И-мэйл хаяг оруулна уу!", trigger: "blur" },
          { type: "email", message: "Зөв и-мэйл оруулна уу!", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "Утасны дугаар оруулна уу!", trigger: "blur" },
          { min: 8, max: 12, message: "Утасны дугаар 8-12 тэмдэгт орсон байх ёстой.", trigger: "blur" },
        ],
      },
        // gender: [
        //   {required: true, message: 'Хүйсээ сонгоно уу!', trigger: 'change'}
        // ]
    };
  },

  computed: {
    ...mapGetters(['isLoadingAuth', 'user']),
  },

  created() {
    this.ruleForm.userId = this.user.id;
    this.ruleForm.avatar = this.user.avatar;
    this.ruleForm.firstname = this.user.first_name;
    this.ruleForm.lastname = this.user.last_name;
    this.ruleForm.email = this.user.email;
    // this.ruleForm.gender = this.user.gender;
    this.ruleForm.phone = this.user.phone;
  },

  methods: {
    ...mapActions(['saveUserInfo']),
    onSelect(event) {
      const file = event.files[0];
      this.ruleForm.avatar = file;
    },
    // async onUpload() {
    //   const formData = new FormData();
    //   formData.append("avatar", this.selectedFile);
    //
    //   try {
    //     const response = await fetch(`${config.HOST}/lambda/krud/upload`, {
    //       method: "POST",
    //       body: formData,
    //     });
    //
    //     if (!response.ok) {
    //       throw new Error("Зураг оруулахад алдаа гарлаа.");
    //     }
    //
    //     console.log("Амжилттай:", await response.json());
    //   } catch (error) {
    //     console.error("Алдаа:", error);
    //   }
    // },
    submitForm() {
      // this.$refs.ruleForm.validate((valid) => {
      //   if (valid) {
          this.saveUserInfo(({vm: this}))
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
  },
};
</script>
<style>
.email-form {
  color: #9697a3;
  height: 44px;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  outline-color: #e67e22;
  background-color: rgba(35, 45, 64, 0.5);
}
</style>
