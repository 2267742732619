<template>
  <div class="stream-page">
    <div class="stream-page-flex">
      <div class="stream-watch">
        <div v-if="isLoadingEpisode || media == null" class="loader-wrap">
          <ProgressSpinner/>
        </div>
        <div v-else class="player-wrap">
          <div v-if="isLoadingEpisode || episode == null" class="loader-wrap">
            <ProgressSpinner/>
          </div>

          <div v-else-if="!isLoadingEpisode && isBlock" class="mob-block">
            <a href="javascript:void(0)" class="close-btn" @click="goBack"><i class="ti-arrow-left"></i></a>

            <p>Гар утасны аппликейшн татаж үзнэ үү</p>
            <div class="app-links">
              <a href="#"
                 target="_blank">
                <img :src="PlayStore" alt=""/> Playstore
              </a>
              <a
                  href="https://apps.apple.com/us/app/seeroo-tv/id6476870444"
                  target="_blank">
                <img :src="AppStore" alt=""/> App Store
              </a>
            </div>
          </div>

          <div v-else-if="!isLoadingEpisode && episode != null && !isBlock">
            <div v-if="canWatch" class="video-wrapper">
              <router-view :key="$route.fullPath" :episode="episode"
                           :backLink="backLink"></router-view>
            </div>
            <div v-else class="video-wrapper">
              <div v-if="warnMsg != ''" class="watch-warning py-16 md:py-36 sm:py-16">
                <i class="uil-info-circle"></i>
                <span class="text-center">{{ warnMsg }}</span>
                <div class="nav-to-auth" v-if="user == null">
                  <a href="#auth-modal">
                    <i class="uil-user"></i>
                    <span>Нэвтрэх</span>
                  </a>
                  <a href="#register-modal">
                    <i class="uil-pen"></i>
                    <span>Бүртгүүлэх</span>
                  </a>
                </div>

                <div v-else class="nav-to-auth">
                  <router-link to="/payment">
                    <i class="uil-credit-card"></i>
                    <span>Эрх сунгах</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- HZ Sidebar action tools -->
      <div class="lg:hidden h-12 min-w-[220px] media-mini-bar">
        <div class="flex justify-between justify-center">
          <div class="flex justify-center ml-1">
            <nav id="nav" class="w-12 relative">
              <span
                  class="absolute h-[3px] mt-11 w-full bg-[#c11010] shadow ease-out transition-transform transition-medium rounded"
                  :style="{ transform: `translateX(calc(100% * ${selected}))` }"
              ></span>
              <ul class="relative w-48 horiznav">
                <li>
                  <button
                      v-tooltip.top="'Сэтгэгдэл'"
                      type="button"
                      @click="selectTab(0)"
                      :aria-selected="selected === 0"
                      class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline"
                  >
                    <i class="uil uil-comment text-2xl"></i>
                  </button>
                </li>
                <li>
                  <button
                      v-tooltip.top="'Ангиуд'"
                      type="button"
                      @click="selectTab(1)"
                      :aria-selected="selected === 1"
                      class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline"
                  >
                    <i class="uil uil-list-ol text-2xl"></i>
                  </button>
                </li>
                <li>
                  <button
                      v-tooltip.top="'Тухай'"
                      type="button"
                      @click="selectTab(2)"
                      :aria-selected="selected === 2"
                      class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline"
                  >
                    <i class="uil uil-info-circle text-2xl"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <div class="w-18 flex flex row">
            <!--            <div @click="showMenuStatus" v-tooltip.left="{ value: menutoggle ? 'Хураах' : 'Нээх'}"-->
            <!--                 class="w-11 py-2 flex flex justify-center border-l-[1px] border-gray-500 items-center h-full focus:text-orange-500 hover:bg-gray-800">-->
            <!--              <i class="text-2xl pi " :class="menutoggle ? 'pi-chevron-up' : 'pi-chevron-down'"></i>-->
            <!--            </div>-->
            <!--            <Button v-tooltip.left="'Алдаа мэдэгдэх'" icon="pi pi-pencil"-->
            <!--                    class="p-button-text p-button-rounded p-button p-button-sm mr-2 mt-[5px]">-->
            <!--            </Button>-->
            <i class="pi pi-share-alt text-lg p-3 ml-1 text-[#E92427] hover:text-[#e5e5d8]"
               v-tooltip.left="'Хуваалцах'"></i>
            <i class="pi pi-pencil text-lg p-3 pt-2 m-1 text-[#E92427] hover:text-[#e5e5d8]"
               v-tooltip.left="'Алдаа мэдэгдэх'"></i>
          </div>
        </div>
      </div>

      <!--Main sidebar-->
      <div v-if="menutoggle" class="media-side-info w-full lg:w-[350px] lg:h-screen">
        <div v-if="media == null" class="ep-info-header loader-wrap">
          <ProgressSpinner/>
        </div>

        <div v-else class="p-3 ep-info-header">
          <Image
              :src="media.poster_hz"
              alt="info banner"
              class="w-full h-auto object-cover rounded-lg hidden lg:flex"
          />

          <div class="ep-info-detail">
            <div v-if="episode != null">
              <div class="relative flex items-center justify-between h-14">
                <span class="text-sm">Episodes 1-{{ episodes.length }}</span>
                <span class="text-sm main-text">
                  {{ episode.created_at ? episode.created_at.substr(0, 10) : '' }}
                </span>
              </div>

              <h3 class="ep-media-title">{{ media.title }}</h3>

              <div class="relative flex items-center justify-between h-10">
                <div class="flex items-stretch text-sm">
                  <a href="javascript:void(0)" @click="setStatus('like', episode.liked == 1 ? null : 1)"
                     :class="`btn btn-link btn-sm text-gray-50 hover:text-accent hover:no-underline p-0 m-0 mr-2 like-btn ${episode.liked == 1 ? 'active' : ''}`">
                    <i class="uil-heart"></i>
                    {{ episode.likeCount }}
                  </a>

                  <a href="javascript:void(0)" @click="setStatus('like', episode.liked == 0 ? null : 0)"
                     :class="`btn btn-link btn-sm text-gray-50 hover:text-accent hover:no-underline p-0 m-0 like-btn ${episode.liked == 0 ? 'active' : ''}`">
                    <i class="uil-thumbs-down"></i>
                    {{ episode.dislikeCount }}
                  </a>
                </div>
                <div class="text-sm text-gray-50">
                  {{ $store.state['commentCount'] }} share
                </div>
              </div>
            </div>
            <div v-else class="loader-wrap">
              <ProgressSpinner style="width:50px;height:50px" strokeWidth="8"/>
            </div>
          </div>
        </div>

        <div class="ep-info-body">
          <!-- Comment section -->
          <div v-if="selected == 0" class="com">
            <Comment v-if="episode != null"/>
          </div>

          <!-- Episode section -->
          <div v-else-if="selected == 1" class="text-sm text-grey-dark pb-5 items-center">
            <div class="mini-episodes">
              <EpisodeList v-if="media != null" :disable-search="true" :media-id="$route.params.media"/>
            </div>
          </div>

          <!-- Anime info -->
          <div v-else-if="selected == 2">
            <div class="flex flex-row pb-6 m-0 text-sm main-text text-justify ">
              <p v-html="media.description"></p>
            </div>
            <div class="flex flex-row-reverse">
              <Button label="Дэлгэрэнгүй" class="p-button-raised p-button p-button-sm"
                      @click="handleClick(episode.anime_id)"/>
            </div>
          </div>
        </div>

        <div class="p-3 ep-info-header">

        </div>
      </div>

      <!-- Sidebar action tools -->
      <div class="lg:flex md:w-[48px] lg:h-screen md:h-12 media-mini-bar">
        <div class="flex flex-col justify-between justify-center">
          <div>
            <div v-if="!mirror" tabindex="0" class="m-1 user-control mt-4" @click="openUserMenu">
              <router-link to="/">
                <Button v-tooltip.left="'Нүүр хуудас'" icon="pi pi-home"
                        class="p-button-outlined p-button-rounded p-button p-button-sm">
                </Button>
              </router-link>
            </div>
            <Sidebar v-model:visible="visibleUserMenu"/>
          </div>

          <div class="flex justify-center">
            <nav class="w-12 relative mini-bar-link">
              <span
                  v-if="!mirror"
                  class="absolute h-12 w-[3px] bg-[#E92427] shadow ease-out transition-transform transition-medium anchor"
                  :style="{ transform: `translateY(calc(100% * ${selected}))` }"
              ></span>
              <ul class="relative">
                <li>
                  <button
                      v-tooltip.left="'Ангиуд'"
                      type="button"
                      @click="selectTab(1)"
                      :aria-selected="selected === 1"
                      class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline"
                  >
                    <i class="uil uil-list-ol text-2xl"></i>
                  </button>
                </li>
                <li>
                  <button
                      v-tooltip.left="'Тухай'"
                      type="button"
                      @click="selectTab(2)"
                      :aria-selected="selected === 2"
                      class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline"
                  >
                    <i class="uil uil-info-circle text-2xl"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <div class="h-12">
            <div @click="showMenuStatus"
                 v-tooltip.left="menutoggle ? 'Хураах' : 'Нээх'"
                 class="h-12 px-3 flex flex justify-center border-t-[1px] border-gray-500 items-center w-full focus:text-orange-500 side-toggler">
              <i v-if="menutoggle" class="pi pi-chevron-right"></i>
              <i v-else class="pi pi-chevron-left"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Comment from "@components/Comment.vue";
import EpisodeList from "@components/EpisodeList.vue";
import {mapGetters, mapActions} from "vuex";
import {setModalActive} from "../../store";
import Sidebar from "@components/AuthSidebar.vue";
import {imageUrl} from "../../utils";
import AppStore from "../../assets/appstore.png";
import PlayStore from "../../assets/playstore.png";

export default {
  components: {
    Comment,
    EpisodeList,
    "Sidebar": Sidebar
  },

  el: "#nav",
  data() {
    return {
      AppStore,
      PlayStore,
      selected: 1,
      isLoadingEpisodeList: false,
      reportLoader: false,
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down', 'tse'],
      toggle: true,
      menutoggle: true,
      textLength: 100,
      value: 3.7,
      rateActive: false,
      rateInfoActive: false,
      rating: 3,
      visibleUserMenu: false,
      isBlock: false,
      adultAccept: false,
      items: [
        {
          label: 'Update',
          icon: 'pi pi-facebook',
          command: () => {
            this.$toast.add({severity: 'success', summary: 'Update', detail: 'Data Updated'});
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-youtube',
          command: () => {
            this.$toast.add({severity: 'error', summary: 'Delete', detail: 'Data Deleted'});
          }
        },
        {
          label: 'Upload',
          icon: 'pi pi-twitter',
          command: () => {
            this.$toast.add({severity: 'error', summary: 'Delete', detail: 'Data Deleted'});
          }
        },
      ]
    };
  },

  computed: {
    ...mapGetters(['episode', 'media', 'backLink', 'canWatch', 'isFree', 'warnMsg', 'user', 'comments', 'isLoadingEpisode', 'episodes', 'mirror']),
  },

  created() {
    if (window.init.isMobileBlock && window.init.isMobileDevice) {
      this.isBlock = true;
    } else if (window.init.isMobileBlock && window.screen.width <= 1024) {
      this.isBlock = true;
    }

    if (this.$route.params.episode && this.$route.params.episode != 'init') {
      this.getEpisodeDetail({vm: this, episodeID: this.$route.params.episode});
    }

    if (this.$route.params.media) {
      this.getMediaDetail({vm: this, mediaID: this.$route.params.media})
    }

    if (localStorage.getItem('activeTab')) {
      this.selected = this.mirror ? 1 : localStorage.getItem('activeTab');
    }
  },

  beforeMount() {
    const body = document.querySelector("body");
    body.classList.add('media-open');
  },

  unmounted() {
    const body = document.querySelector("body");
    this.$store.dispatch('clearEpisode');

    setTimeout(() => {
      body.classList.remove('media-open');
    }, 300)
  },

  methods: {
    imageUrl,
    ...mapActions(['user', 'getEpisodeDetail', 'reportEpisode', 'getMediaDetail', 'setStatus']),

    isOverflowing() {
      let element = this.$refs.cartList;
      return (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
    },

    rateActiveMouseOver() {
      this.rateActive = true;
      this.rateInfoActive = false;
    },

    rateActiveMouseLeave() {
      this.rateActive = false;
    },

    rateInfoActiveMouseOver() {
      this.rateInfoActive = true;
      this.rateActive = false;
    },

    rateInfoActiveMouseLeave() {
      this.rateInfoActive = false;
    },

    showMenuStatus() {
      this.menutoggle = !this.menutoggle;
    },

    selectTab(i) {
      localStorage.setItem("activeTab", i);
      this.selected = i;
    },

    handleClick(animeID) {
      this.$router.push({path: '/', query: {media: animeID}});
      setModalActive(true);
    },

    openUserMenu() {
      this.visibleUserMenu = true;
      this.getHistoryEpisodes();
    },

    goBack() {
      this.$router.back();
    },

    setStatus(action, value) {
      if (this.user == null) {
        this.$toast.error("Нэвтэрч орсны дараа уг үйлдлийг хийх боломжтой");
        return;
      }

      this.$store.dispatch('setEpStatus', {vm: this, action, value})
    },

    acceptAdult() {
      this.adultAccept = true
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.speeddial-linear-demo) {
  .p-speeddial-direction-down {
    left: calc(50% - 2rem);
    top: 0;
  }
}
</style>
